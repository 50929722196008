import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'

const prodConfig = {
    apiKey: "AIzaSyDGTzIn_l9TdygqJr1ptX0mUDqLtgRYLXQ",
    authDomain: "best-bill-splitter.firebaseapp.com",
    projectId: "best-bill-splitter",
    storageBucket: "best-bill-splitter.appspot.com",
    messagingSenderId: "943821530431",
    appId: "1:943821530431:web:50109bd5fb061f1b0bdf8a",
    measurementId: "G-NV0NKE359C"
}

const firebaseApp = firebase.initializeApp(prodConfig)

export default firebaseApp

export const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')